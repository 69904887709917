<template>
    <div class="global-config-view">
        <div class="ctrl-bar">
            <n-button
                class="submit"
                type="primary"
                size="small"
                @click="onSave"
            >
                保存
            </n-button>
        </div>
        <div class="editor-view">
            <JsonEditor
                class="editor"
                style="height: 600px"
                v-model="configData"
                :mode-list="['tree', 'code']"
                current-mode="tree"
            ></JsonEditor>
        </div>
    </div>
</template>

<style lang="less">
@import '../../../common/common.less';

.global-config-view {
    .common-content;
    .ctrl-bar {
        .common-ctrl-bar;
    }

    .editor-view {
        margin-top: 10px;
    }
}
</style>

<script setup>
import { ref } from 'vue';

import { NButton, useMessage } from 'naive-ui';

import JsonEditor from 'json-editor-vue3';

import { SettingsAPI, getGlobalConfig } from '@/common/API';

import { useGlobalStore } from '@/stores/global';

const message = useMessage();

const globalStore = useGlobalStore();

let configData = ref({});

getGlobalConfig().then(res => {
    if (res.error_no !== 0) {
        message.error(res.error_msg || '未知异常导致加载失败');
        return;
    }
    configData.value = res.data || {};
});

function onSave() {
    SettingsAPI.updateGlobalConfig(configData.value).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        message.success('保存成功');
        globalStore.updateGlobalConfig();
    });
}
</script>
